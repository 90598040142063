@use "@angular/material" as mat;
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

html {
  scroll-behavior: smooth;
}

$primary-palette: (
  50: var(--color-primary-50),
  100: var(--color-primary-100),
  200: var(--color-primary-200),
  300: var(--color-primary-300),
  400: var(--color-primary-400),
  500: var(--color-primary-500),
  600: var(--color-primary-600),
  700: var(--color-primary-700),
  800: var(--color-primary-800),
  900: var(--color-primary-900),
  A100: var(--color-primary-A100),
  A200: var(--color-primary-A200),
  A400: var(--color-primary-A400),
  A700: var(--color-primary-A700),
  contrast: (
    50: var(--contrast-color-primary-50),
    100: var(--contrast-color-primary-100),
    200: var(--contrast-color-primary-200),
    300: var(--contrast-color-primary-300),
    400: var(--contrast-color-primary-400),
    500: var(--contrast-color-primary-500),
    600: var(--contrast-color-primary-600),
    700: var(--contrast-color-primary-700),
    800: var(--contrast-color-primary-800),
    900: var(--contrast-color-primary-900),
    A100: var(--contrast-color-primary-A100),
    A200: var(--contrast-color-primary-A200),
    A400: var(--contrast-color-primary-A400),
    A700: var(--contrast-color-primary-A700),
  ),
);
$accent-palette: (
  50: var(--color-accent-50),
  100: var(--color-accent-100),
  200: var(--color-accent-200),
  300: var(--color-accent-300),
  400: var(--color-accent-400),
  500: var(--color-accent-500),
  600: var(--color-accent-600),
  700: var(--color-accent-700),
  800: var(--color-accent-800),
  900: var(--color-accent-900),
  A100: var(--color-accent-A100),
  A200: var(--color-accent-A200),
  A400: var(--color-accent-A400),
  A700: var(--color-accent-A700),
  contrast: (
    50: var(--contrast-color-accent-50),
    100: var(--contrast-color-accent-100),
    200: var(--contrast-color-accent-200),
    300: var(--contrast-color-accent-300),
    400: var(--contrast-color-accent-400),
    500: var(--contrast-color-accent-500),
    600: var(--contrast-color-accent-600),
    700: var(--contrast-color-accent-700),
    800: var(--contrast-color-accent-800),
    900: var(--contrast-color-accent-900),
    A100: var(--contrast-color-accent-A100),
    A200: var(--contrast-color-accent-A200),
    A400: var(--contrast-color-accent-A400),
    A700: var(--contrast-color-accent-A700),
  ),
);

$my-primary: mat.define-palette($primary-palette);
$my-accent: mat.define-palette($accent-palette);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
  )
);

@include mat.all-legacy-component-themes($my-theme);

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/helpers/visually-hidden";
@import "node_modules/bootstrap/scss/carousel";

h2[id]::before {
  @apply block mt-[-116px] h-[116px] invisible pointer-events-none;
  content: " ";
}

@layer components {
  .policies-text {
    h1,
    h2,
    h3 {
      @apply font-medium;
    }
    h1 {
      @apply text-3xl;
    }
    h2 {
      @apply text-2xl;
    }
    h3 {
      @apply text-xl;
    }

    ul {
      @apply list-disc;
    }

    ol {
      @apply list-decimal;
    }
  }
}
.mat-step-header.cdk-program-focused, .mat-step-header:hover {
  @apply bg-transparent;
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  @apply bg-gofeels-error #{!important};
}
